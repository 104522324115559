<template>
	<div class="doctor-list">

		<el-card class="filter-container" shadow="never">

			<div style="margin-top: 15px">
				<el-form :inline="true" :model="form" size="small"  label-width="100px">
                    <el-row>
                        <el-form-item label="所属栏目：">
                            <el-select v-model="form.selectedType" placeholder="栏目">
                                <el-option v-for="item in typesData" :key="item.id" :label="item.menuName" :value="item.id"  placeholder="请选择所属栏目"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="启用状态：">
                            <el-select v-model="form.selectedState" placeholder="请选择状态">
                                <el-option label="已审核" value="1"></el-option>
                                <el-option label="未审核" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="发布时间：">
                            <el-date-picker v-model="form.selectedDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                                            end-placeholder="结束日期"  value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="文章标题：">
                            <el-input style="width:510px" v-model="keyword" placeholder="文章标题"></el-input>
                        </el-form-item>
                        <el-form-item style="float: right;">
                            <el-button type="primary" size="small" @click="getonelist()">
                                查询
                            </el-button>
                            <el-button type="info" size="small" @click="clearlist">
                                重置
                            </el-button>
                            <el-button type="info" size="small"  @click="del()" plain>
                                批量删除
                            </el-button>
                        </el-form-item>
                    </el-row>
				</el-form>

			</div>
		</el-card>

		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>回收站列表</span></el-col>
				<el-col :xs="20" :sm="20" :md="18" :lg="6" :xl="4">
				<!-- 	<el-button class="btn-add" size="mini">
						保存排序
					</el-button> -->

					
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;" shadow="never">
			<el-table :data="tableData" style="width: 100%;"  @selection-change="checkAll">
				
					
				<el-table-column type="selection" width="55" align="center">
				</el-table-column>
				<el-table-column label="排序" align="center">
					<template slot-scope="scope">
						<el-input style="width: 50px;" :disabled="true" v-model="scope.row.listorder" @blur="blur(scope)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="id" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.nId }}</span>
					</template>
				</el-table-column>
				<el-table-column label="文章标题" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.newTitle }}</span>
					</template>
				</el-table-column>

				<el-table-column label="所属栏目" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.menuName }}</span>
						<!-- <el-select v-model="form.region" placeholder="属性">
							<el-option label="区域一" value="shanghai"></el-option>
							<el-option label="区域二" value="beijing"></el-option>
						</el-select> -->
					</template>
				</el-table-column>

			<!-- 	<el-table-column label="文章属性" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>
 -->			
				
				<!-- <el-table-column label="是否启用" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.newsOpen"  @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column> -->

				<el-table-column label="发布时间" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.newsTime }}</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click="back(scope.row.nId)">还原 </el-button>
                            <el-button @click="del($event,scope.row.nId)" size="mini" type="text" >删除</el-button>
						</p>
						
					</template>
				</el-table-column>
			
			</el-table>
		</el-card>



		<el-pagination :hide-on-single-page="hideonsinglepage" background :current-page="pageNo"
		 @current-change="pagebtn" :total="box.totalCount" :page-size="box.pageSize"  layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					selectedType:"",
					selectedState:"",
					selectedDate:["",""]
				},
				tableData: [],
				typesData:[],
				box:[],
				Switch: false,
				hideonsinglepage: false,
				keyword:'',
				pageNo:1,
				pageshow:true,
				checkData:[]
			}
		},
		methods: {
			// 查询
			getonelist(){
				const that=this;
				that.list=[];
				that.pageNo=1;
				that.getlist();
			},
			
			//还原文章
			back(id){
				const that = this;
				that.api('/admin/news/backOpen',{nid:id}).then(res => {
					
					 if (res.code == 200) {
					 	that.getlist();
					 }
				})
			},
			//获取文章分类 
			getTypes(){
				const that = this;
				that.api('/admin/menu/list').then(res => {
					
					 that.typesData = res.data;
				})
			},
			// 获取列表
			getlist() {
				
				const that = this;
				// console.log(that.form.selectedDate[0])
				//1.接口对 newsTypeId 查询参数没做空处理，导致带此空查询参数搜索无结果
				let queryData = {
					pageSize: 10,
					pageNo: that.pageNo,
					keyword: that.keyword,
					checkType:that.form.selectedState,
					startDay:that.form.selectedDate[0],
					endDay:that.form.selectedDate[1],
					newsBack:1
				};
				//2.判断当newsTypeId 查询不为空时，再填此查询参数
				if(that.form.selectedType!==""){
					queryData.newsTypeId=that.form.selectedType
				}
				that.api('/admin/news/list', queryData).then(res => {
					that.box = res.data;
					for(var i =0; i<res.data.pageList.length;i++){
						if(res.data.pageList[i].newsOpen=='1'){
							res.data.pageList[i].newsOpen=true
						}else{
							res.data.pageList[i].newsOpen=false
						}
					}					
					that.tableData = res.data.pageList;
					that.pageNo = res.data.pageNo;
					this.pageshow = false; //让分页隐藏
					this.$nextTick(() => { //重新渲染分页
						this.pageshow = true;
					});
				})
			},
			// 单条排序
			blur(e) {
				const that = this;
				var data = [{
					nid: e.row.nId,
					listorder: Number(e.row.listorder)
				}]
			
				that.api('/admin/news/editOrder', data).then(res => {
					if (res.code == 200) {
						that.getlist();
					}
				})
			},
			// 按钮开关
			switchbtn(e){
				const that=this;
				that.api('/admin/news/editState',{nid:e.row.nId}).then(res=>{
					if(res.code==200){
						// that.getlist();
					}
				})
			},
			clearlist(){
				const that = this;
				that.pageNo=1;
				that.keyword="";
				that.form.selectedState="";
				that.form.selectedDate.splice(0,1,"");
				that.form.selectedDate.splice(1,1,"");
				that.form.selectedType="";
				that.getlist();
			},
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},
			// 多选处理
			checkAll(val){
				let newVal = [];
				val.forEach(function(o,i){
					newVal.push({"nid":o.nId});
				});
				this.checkData = newVal;
				// console.log(JSON.stringify(newVal));
			},
			// 删除方法
			del(e,delId){
				
				const that = this;
				let delData = [];
				if(delId){
					delData.push({"nid":delId});
				}
				else{
					delData = that.checkData;
				}
				// delData = JSON.stringify(delData);
				// console.log(delData);

                this.$confirm('您确认要删除此条文章么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/news/delCompletely',delData).then(res=>{
                        if(res.code==200){
                            that.$message.success("删除成功!");
                            that.getlist();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
			},
			// 添加或修改
			goNewsEdit(e){
				const that = this;
				that.$router.push({name: 'newsadd',query:{nid: e ?  e.row.nId : ''}})
			}
		},
		created() {
			const that = this;
			that.getlist();
			that.getTypes();
		}
	}
</script>

<style lang="less" scoped>
	.btn-del{
		float:right;
	}
	.sdel-btn{
		margin-left:10px;
	}
</style>
